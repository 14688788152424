<template>
  <footer class="footer-creative">
    <div class="goto-top" :class="toTopBtnFlag?'goto-top-float':''">
      <div class="chat-online" @click="linkUrl(chatUrl)">
        <i class="ri-customer-service-line"></i>
        在线咨询
      </div>
      <div class="back-top" @click="backTop">
        <i class="ri-skip-up-line"></i>
        回到顶部
      </div>
    </div>
    <div class="container">
      <div class="footer-minimal-bottom-panel text-sm-left" style="border-bottom: 1px solid rgba(217,217,217,.3);">
        <el-row>
          <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13">
            <div class="footer-logo">
              <img src="../assets/images/footer-logo.png" alt="">
            </div>
            <div class="footer-info-left">
              <div class="footer-qrcode">
                <img src="../assets/images/footer-qrcode-wechat.png" alt="">
                微信公众号
              </div>
              <div class="footer-qrcode">
                <img src="../assets/images/footer-qrcode-bili.png" alt="">
                哔哩哔哩官方号
              </div>
              <ul class="footer-info-detail">
                <li>联系电话：13664132238</li>
                <li>总部地址：辽宁省沈阳市浑南区创新路 175-2号B2座301室</li>
                <li>技术支持：support@dotrustech.com</li>
                <li>市场活动：market@dotrustech.com</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="11" :lg="11" :xl="11">
            <el-row class="footer-links">
              <el-col :xs="7" :sm="7" :md="7" :lg="6" :xl="6">
                <h3>解决方案</h3>
                <ul>
                  <li><router-link to="/solution/electricalArchitecture">电子电气架构开发</router-link></li>
                  <li><router-link to="/solution/controllerSoftware">嵌入式软件开发</router-link></li>
                  <li><router-link to="/solution/automotiveNetwork">网络测试</router-link></li>
                  <li><router-link to="/solution/adas">仿真测试</router-link></li>
                  <li><router-link to="/solution/vehicleTest">整车测试</router-link></li>
                  <li><router-link to="/solution/securityTest">信息安全测试</router-link></li>
                  <li><router-link to="/solution/toolSoftware">应用软件</router-link></li>
                </ul>
              </el-col>
              <el-col :span="6">
                <h3>产品中心</h3>
                <ul>
                  <li><router-link to="/product/vectorSoftware">VECTOR</router-link></li>
                  <li><router-link to="/product/VTD">HEXAGON</router-link></li>
                  <li><router-link to="/product/Qt">Qt</router-link></li>
                  <li><router-link to="/product/RTaW">RTaW</router-link></li>
                  <li><router-link to="/product/NXP">NXP</router-link></li>
                </ul>
              </el-col>
              <el-col :span="6">
                <h3>资源共享</h3>
                <ul>
                  <li @click="toAnchor('/resource','#courseTrain')"><a>课程培训</a></li>
                  <li @click="toAnchor('/resource','#downloadInfo')"><a>资料下载</a></li>
                  <li @click="toAnchor('/resource','#techPages')"><a>技术好文</a></li>
                  <li @click="toAnchor('/resource','#companyInfo')"><a>公司动态</a></li>
                </ul>
              </el-col>
              <el-col :xs="5" :sm="5" :md="5" :lg="6" :xl="6">
                <h3>关于我们</h3>
                <ul>
                  <li @click="toAnchor('/about','#companyIntro')"><a>公司介绍</a></li>
                  <li @click="toAnchor('/about','#honor')"><a>荣誉资质</a></li>
                  <li @click="toAnchor('/about','#partner')"><a>合作伙伴</a></li>
                  <li><router-link to="/joinUs">加入我们</router-link></li>
                </ul>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <div class="copyright">
        版权所有 &copy; 沈阳东信创智科技有限公司 2023 辽ICP备15015453号-1
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'mainNav',
  props: {
    activeIndex: String
  },
  data() {
    return {
      toTopBtnFlag:false,
      scrollTopVal: 0,
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf"
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 开启滚动条监听
      window.addEventListener("scroll", this.scrollToTop, true);
    })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollToTop, true);
  },
  methods: {
    // scrollTop() {
    //   this.scrollTopVal = document.documentElement.scrollTop;
    // },
    backTop () {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop () {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop;
      that.btnFlag = that.scrollTop > 60;
      if (that.scrollTop > 630) {
        that.toTopBtnFlag = true;
      }else{
        that.toTopBtnFlag = false;
      }
    },
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    toAnchor(path, anchorName){
      const routePath = this.$route.path;
       if (path == routePath){
         this.$emit("checkRouter", anchorName);
       } else {
         this.$router.push(path + anchorName);
       }
    }
  }
}
</script>
