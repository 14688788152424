<template>
	<header class="main-header"
		:style="{'background-color': scrollTopVal < 100 ? 'rgba(0, 32, 72, .1)' : 'rgba(0, 32, 72, 0.95)'} ">
		<div class="main-nav container">
			<router-link class="logo" to="/"><img src="../assets/images/logo-inverse.png" alt="" />
			</router-link>
			<div class="nav-right">
				<router-link to="/joinUs"><i class="ri-user-add-line"></i>加入我们</router-link>
				<router-link to="/contact"><i class="ri-contacts-line"></i>联系我们</router-link>
				<search-select @checkRouter="checkRouter"></search-select>
			</div>
			<el-menu :default-active="activeIndex" class="pc-menu" mode="horizontal" active-text-color="#ffd04b"
				@select="handleSelect">
				<el-menu-item index="1">
					首页
				</el-menu-item>
				<el-submenu index="2" :class="activeIndexSubstring == 2 ? 'is-selected' : ''">
					<template slot="title">解决方案</template>
					<div class="dropdown-menu-full">
						<div class="dropdown-container">
							<ul>
								<li :class="{ active: solutionSelectedItem === 1 }" @click="solutionSelectedItem = 1">
									电子电气架构开发</li>
								<li :class="{ active: solutionSelectedItem === 8 }" @click="solutionSelectedItem = 8">
									车载控制器开发</li>
								<li :class="{ active: solutionSelectedItem === 2 }" @click="solutionSelectedItem = 2">
									嵌入式软件开发</li>
								<li :class="{ active: solutionSelectedItem === 3 }" @click="solutionSelectedItem = 3">
									车载网络测试</li>
								<li :class="{ active: solutionSelectedItem === 4 }" @click="solutionSelectedItem = 4">
									功能仿真测试</li>
								<li :class="{ active: solutionSelectedItem === 5 }" @click="solutionSelectedItem = 5">
									整车测试</li>
								<li :class="{ active: solutionSelectedItem === 7 }" @click="solutionSelectedItem = 7">
									应用软件开发</li>
							</ul>
							<div class="menu-block-container" v-show="solutionSelectedItem === 1">
								<div class="menu-block">
									<router-link to="/solution/electricalArchitecture" class="custom-link"
										:class="{ 'active': $route.path === '/solution/electricalArchitecture' }" exact>
										<img src="../assets/images/dropdown-menu-solution-1-1.png">
										电子电气架构开发
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/preevision" class="custom-link"
										:class="{ 'active': $route.path === '/solution/preevision' }" exact>
										<img src="../assets/images/dropdown-menu-solution-1-2.png">
										PREEvision二次开发
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/preevision2x" class="custom-link"
										:class="{ 'active': $route.path === '/solution/preevision2x' }" exact>
										<img src="../assets/images/dropdown-menu-solution-1-3.png">
										PREEvision数据交换
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 8">
								<div class="menu-block">
									<router-link to="/solution/Vehicle" class="custom-link"
										:class="{ 'active': $route.path === '/solution/Vehicle' }" exact>
										<img src="../assets/images/dropdown-menu-solution-1-1.png">
										车载控制器开发
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/gateway" class="custom-link"
										:class="{ 'active': $route.path === '/solution/gateway' }" exact>
										<img src="../assets/images/dropdown-menu-solution-1-2.png">
										网关快速原型开发
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/hmi" class="custom-link"
										:class="{ 'active': $route.path === '/solution/hmi' }" exact>
										<img src="../assets/images/dropdown-menu-solution-1-3.png">
										车载HMI开发
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 2">
								<div class="menu-block">
									<router-link to="/solution/controllerSoftware" class="custom-link"
										:class="{ 'active': $route.path === '/solution/controllerSoftware' }" exact>
										<img src="../assets/images/dropdown-menu-solution-2-1.png">
										AUTOSAR工程服务
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/controllerSoftwareDevelopment" class="custom-link"
										:class="{ 'active': $route.path === '/solution/controllerSoftwareDevelopment' }" exact>
										<img src="../assets/images/dropdown-menu-solution-2-2.png">
										基础软件定制开发
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/controllerSoftwareEmbedded" class="custom-link"
										:class="{ 'active': $route.path === '/solution/controllerSoftwareEmbedded' }" exact>
										<img src="../assets/images/dropdown-menu-solution-2-2.png">
										适配以太网测试的嵌入式软件开发服务
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 3">
								<div class="menu-block">
									<router-link to="/solution/automotiveNetwork" class="custom-link"
										:class="{ 'active': $route.path === '/solution/automotiveNetwork' }" exact>
										<img src="../assets/images/dropdown-menu-solution-3-1.png">
										CAN/LIN测试
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/vehicleEthernet" class="custom-link"
										:class="{ 'active': $route.path === '/solution/vehicleEthernet' }" exact>
										<img src="../assets/images/dropdown-menu-solution-3-2.png">
										Ethernet测试
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/V2X" class="custom-link"
										:class="{ 'active': $route.path === '/solution/V2X' }" exact>
										<img src="../assets/images/dropdown-menu-solution-6-1.png">
										V2X测试
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 4">
								<div class="menu-block" style="margin-right: 30px;">
									<router-link to="/solution/electronicControl" class="custom-link"
										:class="{ 'active': $route.path === '/solution/electronicControl' }" exact>
										<img src="../assets/images/dropdown-menu-solution-4-2.png">
										智能电控
									</router-link>
								</div>
								<div class="menu-block" style="margin-right: 30px;">
									<router-link to="/solution/cockpit" class="custom-link"
										:class="{ 'active': $route.path === '/solution/cockpit' }" exact>
										<img src="../assets/images/dropdown-menu-solution-4-3.png">
										智能座舱
									</router-link>
								</div>
								<div class="menu-block" style="margin-right: 30px;">
									<router-link to="/solution/adas" class="custom-link"
										:class="{ 'active': $route.path === '/solution/adas' }" exact>
										<img src="../assets/images/dropdown-menu-solution-4-1.png">
										智能驾驶
									</router-link>
								</div>

							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 5">
								<div class="menu-block">
									<router-link to="/solution/vehicleTest" class="custom-link"
										:class="{ 'active': $route.path === '/solution/vehicleTest' }" exact>
										<img src="../assets/images/dropdown-menu-solution-5-1.png">
										整车测试
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 6">
								<div class="menu-block">
									<router-link to="/solution/securityTest" class="custom-link"
										:class="{ 'active': $route.path === '/solution/securityTest' }" exact>
										<img src="../assets/images/dropdown-menu-solution-6-1.png">
										信息安全测试
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="solutionSelectedItem === 7">

								<div class="menu-block">
									<router-link to="/solution/platformSoftware" class="custom-link"
										:class="{ 'active': $route.path === '/solution/platformSoftware' }" exact>
										<img src="../assets/images/dropdown-menu-solution-7-2.png">
										平台软件开发
									</router-link>
								</div>
								<div class="menu-block">
									<router-link to="/solution/toolSoftware" class="custom-link"
										:class="{ 'active': $route.path === '/solution/toolSoftware' }" exact>
										<img src="../assets/images/dropdown-menu-solution-7-1.png">
										工具软件开发
									</router-link>
								</div>

							</div>
						</div>
					</div>
				</el-submenu>
				<el-submenu index="3" :class="activeIndexSubstring == 3 ?'is-selected' : ''">
					<template slot="title">产品中心</template>
					<div class="dropdown-menu-full">
						<div class="dropdown-container">
							<ul>
								<li :class="{ active: productSelectedItem === 1 }" @click="productSelectedItem = 1">
									Vector</li>
								<li :class="{ active: productSelectedItem === 2 }" @click="productSelectedItem = 2">
									HEXAGON</li>
								<li :class="{ active: productSelectedItem === 3 }" @click="productSelectedItem = 3">Qt
								</li>
								<li :class="{ active: productSelectedItem === 4 }" @click="productSelectedItem = 4">RTaW
								</li>
								<li :class="{ active: productSelectedItem === 5 }" @click="productSelectedItem = 5">NXP
								</li>
							</ul>
							<div v-show="productSelectedItem === 1">
								<div class="menu-block-container">
									<div class="menu-block">
										<router-link to="/product/vectorSoftware" class="custom-link"
											:class="{ 'active': $route.path === '/product/vectorSoftware' }" exact>
											<img src="../assets/images/dropdown-menu-product-1-1.png">
											Vector软件产品
										</router-link>
									</div>
									<div class="menu-block">
										<router-link to="/product/vectorHardware" class="custom-link"
											:class="{ 'active': $route.path === '/product/vectorHardware' }" exact>
											<img src="../assets/images/dropdown-menu-product-1-2.png">
											Vector硬件产品
										</router-link>
									</div>
								</div>
								<ul>
									<li>
										<router-link to="/product/DYNA4"
											:class="{ 'active': $route.path === '/product/DYNA4' }" exact>DYNA4
										</router-link>
									</li>
									<li>
										<router-link to="/product/vectorCast"
											:class="{ 'active': $route.path === '/product/vectorCast' }"
											exact>VectorCAST
										</router-link>
									</li>
									<li>
										<router-link to="/product/vp67"
											:class="{ 'active': $route.path === '/product/vp67' }" exact>VP6000/VP7000系列
										</router-link>
									</li>
								</ul>
							</div>
							<div class="menu-block-container" v-show="productSelectedItem === 2">
								<div class="menu-block">
									<router-link to="/product/VTD" class="custom-link"
										:class="{ 'active': $route.path === '/product/VTD' }" exact>
										<img src="../assets/images/dropdown-menu-product-2-1.png">
										VTD
									</router-link>
								</div>
							</div>
							<div class="menu-block-container" v-show="productSelectedItem === 3">
								<div class="menu-block">
									<router-link to="/product/Qt" class="custom-link"
										:class="{ 'active': $route.path === '/product/Qt' }" exact>
										<img src="../assets/images/dropdown-menu-product-3-1.png">
										Qt
									</router-link>
								</div>
							</div>
							<div class="menu-block-container flex-container" v-show="productSelectedItem === 4">
								<div class="menu-block">
									<router-link to="/product/RTaW" class="custom-link"
										:class="{ 'active': $route.path === '/product/RTaW' }" exact>
										<img src="../assets/images/dropdown-menu-product-4-1.png">
										RTaW-Peagase
									</router-link>
								</div>
							</div>
							<div class="menu-block-container flex-container" v-show="productSelectedItem === 5">
								<div class="menu-block">
									<router-link to="/product/NXP" class="custom-link"
										:class="{ 'active': $route.path === '/product/NXP' }" exact>
										<img src="../assets/images/dropdown-menu-product-5-1.png">
										NXP解决方案及产品介绍
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</el-submenu>
				<el-menu-item index="4">
					资源共享
				</el-menu-item>
				<el-menu-item index="5">
					走进东信
				</el-menu-item>
			</el-menu>
			<div class="menu-toggler" @click="toggleMenu()">
				<i class="ri-menu-fill"></i>
			</div>
			<div class="menu-mobile" v-show="mobileMenu">
				<el-menu :default-active="activeIndex" class="el-menu-vertical-demo">
					<el-menu-item index="1" @click="$router.push('/')">
						<i class="el-icon-house"></i>
						<span slot="title">首页</span>
					</el-menu-item>
					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-c-scale-to-original"
								:class="activeIndexSubstring == 2 ? 'is-submenu-selected' : ''"></i>
							<span :class="activeIndexSubstring == 2 ? 'is-submenu-selected' : ''">解决方案</span>
						</template>
						<el-submenu index="2-1" :class="activeIndex == '2-1' ?'is-selected' : ''">
							<template slot="title">电子电气架构开发</template>
							<el-menu-item index="2-1-1">
								<span slot="title">
									<router-link to="/solution/electricalArchitecture"
										:class="{ 'active': $route.path === '/solution/electricalArchitecture' }"
										exact>电子电气架构开发咨询服务</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-1-2">
								<span slot="title">
									<router-link to="/solution/preevision"
										:class="{ 'active': $route.path === '/solution/preevision' }"
										exact>PREEvision二次开发</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-1-3">
								<span slot="title">
									<router-link to="/solution/preevision2x"
										:class="{ 'active': $route.path === '/solution/preevision2x' }"
										exact>PREEvision数据交换平台 (PREEvision2X)</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="2-2" :class="activeIndex == '2-2' ?'is-selected' : ''">
							<template slot="title">嵌入式软件开发</template>
							<el-menu-item index="2-2-1">
								<span slot="title">
									<router-link to="/solution/controllerSoftware"
										:class="{ 'active': $route.path === '/solution/controllerSoftware' }"
										exact>控制器软件集成开发服务</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-2-2">
								<span slot="title">
									<router-link to="/solution/gateway"
										:class="{ 'active': $route.path === '/solution/gateway' }"
										exact>网关快速原型开发服务</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="2-3" :class="activeIndex == '2-3' ?'is-selected' : ''">
							<template slot="title">网络测试</template>
							<el-menu-item index="2-3-1">
								<span slot="title">
									<router-link to="/solution/automotiveNetwork"
										:class="{ 'active': $route.path === '/solution/automotiveNetwork' }"
										exact>汽车CAN/CANFD/FlexRay/LIN网络测试系统</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-3-2">
								<span slot="title">
									<router-link to="/solution/vehicleEthernet"
										:class="{ 'active': $route.path === '/solution/vehicleEthernet' }"
										exact>车载以太网测试系统</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="2-4" :class="activeIndex == '2-4' ?'is-selected' : ''">
							<template slot="title">仿真测试</template>
							<el-menu-item index="2-4-1">
								<span slot="title">
									<router-link to="/solution/adas"
										:class="{ 'active': $route.path === '/solution/adas' }" exact>智能驾驶</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-4-2">
								<span slot="title">
									<router-link to="/solution/electronicControl"
										:class="{ 'active': $route.path === '/solution/electronicControl' }"
										exact>智能电控</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-4-3">
								<span slot="title">
									<router-link to="/solution/cockpit"
										:class="{ 'active': $route.path === '/solution/cockpit' }"
										exact>智能座舱</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-4-4">
								<span slot="title">
									<router-link to="/solution/networkConnection"
										:class="{ 'active': $route.path === '/solution/networkConnection' }"
										exact>智能网联</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="2-5" :class="activeIndex == '2-5' ?'is-selected' : ''">
							<template slot="title">整车测试</template>
							<el-menu-item index="2-5-1">
								<span slot="title">
									<router-link to="/solution/vehicleTest"
										:class="{ 'active': $route.path === '/solution/vehicleTest' }"
										exact>整车测试</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="2-6" :class="activeIndex == '2-6' ?'is-selected' : ''">
							<template slot="title">信息安全测试</template>
							<el-menu-item index="2-6-1">
								<span slot="title">
									<router-link to="/solution/securityTest"
										:class="{ 'active': $route.path === '/solution/securityTest' }"
										exact>信息安全测试</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="2-7" :class="activeIndex == '2-7' ?'is-selected' : ''">
							<template slot="title">应用软件</template>
							<el-menu-item index="2-7-1">
								<span slot="title">
									<router-link to="/solution/toolSoftware"
										:class="{ 'active': $route.path === '/solution/toolSoftware' }"
										exact>工具软件定制开发</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-7-2">
								<span slot="title">
									<router-link to="/solution/platformSoftware"
										:class="{ 'active': $route.path === '/solution/platformSoftware' }"
										exact>平台软件定制开发</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="2-7-3">
								<span slot="title">
									<router-link to="/solution/adasData"
										:class="{ 'active': $route.path === '/solution/adasData' }"
										exact>ADAS数据采集及回灌系统开发</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">
							<i class="el-icon-box" :class="activeIndexSubstring == 3 ?'is-submenu-selected' : ''"></i>
							<span :class="activeIndexSubstring == 3 ?'is-submenu-selected' : ''">产品中心</span>
						</template>
						<el-submenu index="3-1" :class="activeIndex == '3-1' ?'is-selected' : ''">
							<template slot="title">Vector</template>
							<el-menu-item index="3-1-1">
								<span slot="title">
									<router-link to="/product/vectorSoftware"
										:class="{ 'active': $route.path === '/product/vectorSoftware' }"
										exact>Vector软件产品</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="3-1-2">
								<span slot="title">
									<router-link to="/product/vectorHardware"
										:class="{ 'active': $route.path === '/product/vectorHardware' }"
										exact>Vector硬件产品</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="3-1-3">
								<span slot="title">
									<router-link to="/product/DYNA4"
										:class="{ 'active': $route.path === '/product/DYNA4' }"
										exact>DYNA4</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="3-1-4">
								<span slot="title">
									<router-link to="/product/vp67"
										:class="{ 'active': $route.path === '/product/vp67' }"
										exact>VP6000/VP7000系列</router-link>
								</span>
							</el-menu-item>
							<el-menu-item index="3-1-5">
								<span slot="title">
									<router-link to="/product/vectorCast"
										:class="{ 'active': $route.path === '/product/vectorCast' }"
										exact>VectorCAST</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="3-2" :class="activeIndex == '3-2' ?'is-selected' : ''">
							<template slot="title">HEXAGON</template>
							<el-menu-item index="3-2-1">
								<span slot="title">
									<router-link to="/product/VTD" :class="{ 'active': $route.path === '/product/VTD' }"
										exact>VTD</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="3-3" :class="activeIndex == '3-3' ?'is-selected' : ''">
							<template slot="title">Qt</template>
							<el-menu-item index="3-3-1">
								<span slot="title">
									<router-link to="/product/Qt" :class="{ 'active': $route.path === '/product/Qt' }"
										exact>Qt</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="3-4" :class="activeIndex == '3-4' ?'is-selected' : ''">
							<template slot="title">RTaW</template>
							<el-menu-item index="3-4-1">
								<span slot="title">
									<router-link to="/product/RTaW"
										:class="{ 'active': $route.path === '/product/RTaW' }"
										exact>RTaW-Peagase</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
						<el-submenu index="3-5" :class="activeIndex == '3-5' ?'is-selected' : ''">
							<template slot="title">NXP</template>
							<el-menu-item index="3-5-1">
								<span slot="title">
									<router-link to="/product/NXP" :class="{ 'active': $route.path === '/product/NXP' }"
										exact>NXP解决方案及产品介绍</router-link>
								</span>
							</el-menu-item>
						</el-submenu>
					</el-submenu>
					<el-menu-item index="4" @click="$router.push('/resource')">
						<i class="el-icon-receiving"></i>
						<span slot="title">资源共享</span>
					</el-menu-item>
					<el-menu-item index="5" @click="$router.push('/about')">
						<i class="el-icon-office-building"></i>
						<span slot="title">走进东信</span>
					</el-menu-item>
					<el-divider></el-divider>
					<el-menu-item index="6" @click="$router.push('/joinUs')">
						<i class="ri-user-add-line"></i>
						<span slot="title">加入我们</span>
					</el-menu-item>
					<el-menu-item index="7" @click="$router.push('/contact')">
						<i class="ri-contacts-line"></i>
						<span slot="title">联系我们</span>
					</el-menu-item>
				</el-menu>
			</div>
		</div>
	</header>
</template>

<script>
	import SearchSelect from './SearchSelect';
	export default {
		name: 'mainNav',
		components: {
			SearchSelect
		},
		props: {
			activeIndex: {
				type: String
			},
		},
		data() {
			return {
				// 解决方案默认选项
				solutionSelectedItem: 1,
				// 产品中心默认选项
				productSelectedItem: 1,
				scrollTopVal: 0,
				isMobile: false,
				mobileMenu: false
			}
		},
		created() {
			this.getSolutionRouterIndex();
			this.getProductRouterIndex();
		},
		computed: {
			activeIndexSubstring() {
				return this.activeIndex.substring(0, 1);
			}
		},
		mounted() {
			this.$nextTick(() => {
				// 开启滚动条监听
				window.addEventListener("scroll", this.scrollTop, true);
			})
			this.isMobile = document.documentElement.clientWidth < 1100
			window.onresize = () => {
				return (() => {
					this.isMobile = document.documentElement.clientWidth < 1100;
				})();
			}
		},
		beforeDestroy() {
			document.removeEventListener('scroll', this.scrollTop, true);
		},
		methods: {
			handleSelect(index) {
				switch (index) {
					case "1":
						this.$router.push("/");
						break;
					case "4":
						this.$router.push("/resource");
						break;
					case "5":
						this.$router.push("/about");
						break;
					default:
						break;
				}
			},
			scrollTop() {
				this.scrollTopVal = document.documentElement.scrollTop;
			},
			getSolutionRouterIndex() {
				switch (this.$route.path) {
					case "/solution/electricalArchitecture":
					case "/solution/preevision":
					case "/solution/preevision2x":
						this.solutionSelectedItem = 1;
						break;
          case "/solution/controllerSoftwareEmbedded":
          case "/solution/controllerSoftwareDevelopment":
          case "/solution/controllerSoftware":
            this.solutionSelectedItem = 2;
            break;
					case "/solution/automotiveNetwork":
					case "/solution/vehicleEthernet":
					case "/solution/V2X":
						this.solutionSelectedItem = 3;
						break;
					case "/solution/adas":
					case "/solution/electronicControl":
					case "/solution/cockpit":
					case "/solution/networkConnection":
						this.solutionSelectedItem = 4;
						break;
					case "/solution/vehicleTest":
						this.solutionSelectedItem = 5;
						break;
					case "/solution/securityTest":
						this.solutionSelectedItem = 6;
						break;
					case "/solution/toolSoftware":
					case "/solution/platformSoftware":
					case "/solution/adasData":
						this.solutionSelectedItem = 7;
						break;
          case "/solution/hmi":
          case "/solution/gateway":
          case "/solution/Vehicle":
            this.solutionSelectedItem = 8;
            break;
					default:
						break;
				}
			},
			getProductRouterIndex() {
				switch (this.$route.path) {
					case "/product/vectorSoftware":
					case "/product/vectorHardware":
					case "/product/DYNA4":
					case "/product/vp67":
					case "/product/vectorCast":
						this.productSelectedItem = 1;
						break;
					case "/product/VTD":
						this.productSelectedItem = 2;
						break;
					case "/product/Qt":
						this.productSelectedItem = 3;
						break;
					case "/product/RTaW":
						this.productSelectedItem = 4;
						break;
					case "/product/NXP":
						this.productSelectedItem = 5;
						break;
					default:
						break;
				}
			},
			toggleMenu() {
				this.mobileMenu = !this.mobileMenu
				this.mobileMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
			},
			checkRouter(hash) {
				this.$emit("checkRouter", hash);
			}
		},
	}
</script>
