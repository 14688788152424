<template>
  <div class="nav-search-select">
    <a href="javascript:;" @click="searchShow = true"
    ><i class="ri-search-2-line"></i>
      <el-select
          :class="searchShow ? 'search-show' : ''"
          v-model="searchString"
          filterable
          remote
          placeholder="搜索"
          size="mini"
          :remote-method="remoteMethod"
          @change="selectChange"
          v-show="searchShow"
          @click.native="selectOptions = []"
          @focus="selectOptions = []"
      >
        <el-option
            v-for="item in selectOptions"
            :key="item.route"
            :label="item.label"
            :value="item.route"
        >
        </el-option>
      </el-select>
    </a>
  </div>
</template>

<script>
export default {
  name: "SearchSelect",
  data() {
    return {
      searchString: null,
      searchShow: false,
      selectOptions: [],
      searchOptions: [
        {
          route: "/resource#courseTrain",
          label: "资源共享 > 课程培训",
          keys: "培训课程 课程培训 授课 课程 培训 协议培训 工具 硬件 软件 报名 总线 协议",
        },
        {
          route: "/resource#downloadInfo",
          label: "资源共享 > 资料下载",
          keys: "安装包 资料 下载 资料下载 资源 共享 驱动 安装",
        },
        {
          route: "/resource#techPages",
          label: "资源共享 > 技术好文",
          keys: "技术 文章 技术好文 QT HIL MCU 测试 充电 PREEVISION 数据交换 智能驾驶 新能源 实车 架构",
        },
        {
          route: "/resource#companyInfo",
          label: "资源共享 > 公司动态",
          keys: "公司动态 合作 东信创智 活动",
        },
        {
          route: "/about#companyIntro",
          label: "走进东信 > 公司介绍",
          keys: "公司介绍 东信创智 业务 企业文化",
        },
        {
          route: "/about#honor",
          label: "走进东信 > 荣誉资质",
          keys: "荣誉资质 软著 发明专利 认证 企业 公司 证书 软件著作权",
        },
        {
          route: "/about#partner",
          label: "走进东信 > 合作伙伴",
          keys: "合作伙伴 客户 公司 QT VECTOR NXP SPIRENT RTAW HEXAGON NATIONAL INSTRUMENTS",
        },
        {
          route: "/joins",
          label: "走进东信 > 加入我们",
          keys: "校园招聘 社会招聘 社招 校招 实习 岗位 工作 职位 应聘",
        },
        {
          route: "/electric",
          label: "解决方案 > 电子电气架构开发咨询服务",
          keys: "电子电气架构开发咨询服务 电子电气 架构开发 咨询服务 解决方案 SOA",
        },
        {
          route: "/preevision",
          label: "解决方案 > PREEvision二次开发",
          keys: "PREEVISION二次开发 解决方案 PV VECTOR 汽车电子电气架构设计开发",
        },
        {
          route: "/preevision2x",
          label: "解决方案 > PREEvision数据交换平台(PREEvision2X)",
          keys: "PREEVISION数据交换平台(PREEVISION2X) 解决方案 数据交换 自动 流转与监控 分析 E/E架构设计",
        },
        {
          route: "/ecu/soft",
          label: "解决方案 > 控制器软件集成开发服务",
          keys: "控制器软件集成开发服务 解决方案 AUTOSAR 定制 测试 诊断 车载控制器AUTOSAR架构嵌入式软件开发",
        },
        {
          route: "/gateway",
          label: "解决方案 > 网关快速原型开发服务",
          keys: "网关快速原型开发服务 解决方案 控制器 定制 诊断 管理 车型研发",
        },
        {
          route: "/network",
          label: "解决方案 > 汽车CAN/CANFD/FlexRay/LIN网络测试系统",
          keys: "汽车CAN/CANFD/FlEXRAY/LIN网络测试系统 解决方案 自动化测试 协议 通讯 诊断 STORMEYE CANOE",
        },
        {
          route: "/ethernet",
          label: "解决方案 > 车载以太网测试系统",
          keys: "车载以太网测试系统 解决方案 ADAS 自动化测试系统 一致性测试 OEM",
        },
        {
          route: "/drive",
          label: "解决方案 > 智能驾驶",
          keys: "智能驾驶MIL/SIL测试系统 智能车灯XIL测试系统 AR-HUD XIL测试系统 智能驾驶HIL测试系统 智能驾驶VIL测试系统 SIL HIL DIL VTD DYNA4 CANOE VTESTSTUDIO AR-HUD仿真测试 驾驶模拟 VECTOR NI SCMS USSM L1~L5级自动驾驶仿真测试 人机共驾 模拟 仿真 自动化测试 驾驶模拟 L1~L5级自动驾驶仿真测试 智能驾驶DIL测试系统 解决方案 智能化汽车硬件 自动化测试 NI PXI VT STYSTEM VTD DYNA4 CARSIM L1~L5级自动驾驶仿真测试 SIMULINK MIL 仿真 场景 模拟 验证 自动化测试 VTD DYNA4 CANOE VTESTSTUDIO L1~L5级自动驾驶仿真测试",
        },
        {
          route: "/electronic/control",
          label: "解决方案 > 智能电控",
          keys: "智能电控 新能源三电HIL测试系统 智能底盘HIL测试系统 解决方案 自动化测试 VCU MCU BMS ADAS AD VIL VECTOR VT SYSTEM NI PXI DYNA4 CANOE VTESTSTUDIO",
        },
        {
          route: "/cockpit",
          label: "解决方案 > 智能座舱",
          keys: "智能座舱HIL测试系统 解决方案 自动化测试 VECTOR VT NI PXI DYNA4 VTD CANOE 场景模拟 车辆动力学仿真 屏幕 HUD 语音 面部识别 智能座舱驾驶模拟器",
        },
        {
          route: "/network/connection",
          label: "解决方案 > 智能网联",
          keys: "智能网联 车身域HIL测试系统 解决方案 ADAS HIL SIL MIL PIL 仿真模拟 自动化测试 BCM PEPS AC DSCU VCU VTESTSTUDIO CANOE MATLAB SIMULINK ECU FOTA HIL ADB OTA自动化测试 STORMEYE CANOE",
        },
        {
          route: "/vehicle",
          label: "解决方案 > 整车测试",
          keys: "整车测试 解决方案 整车电子电气测试工程服务 测试流程体系搭建 诊断测试 实车道路场景测试 功能测试 实验室部件测试 CAN CANFD LIN AUTOSAR OSEK 整车LABCAR台架测试",
        },
        {
          route: "/security/test",
          label: "解决方案 > 信息安全测试",
          keys: "信息安全测试 解决方案 SECOC TLS IPSEC 网络渗透 漏洞扫描 接口",
        },
        {
          route: "/tool/soft",
          label: "解决方案 > 工具软件定制开发",
          keys: "工具软件定制开发 解决方案 STORMEYE 测试执行管理软件 汽车电子网络测试 功能测试 仿真测试 测试管理 测试执行 问题跟踪 工作效率 协同测试 远程测试 仿真测试 数据转换 模型转换 场景泛化 诊断数据库 上位机 辅助工具 CANOE",
        },
        {
          route: "/platform/soft",
          label: "解决方案 > 平台软件定制开发",
          keys: "平台软件定制开发 STORMETM电子电气集成测试管理系统 STORMSCENA场景管理分析系统 定制化软件开发服务 计划管理 样件管理 过程管理 统计分析 功能库 项目管理 统计分析 信息化 标准化 场景泛化 AI绘图 协同 远程 定制化 PREEVISION 数据大屏 监控 桌面工具 管理系统 数据分析 互联互通",
        },
        {
          route: "/adas",
          label: "解决方案 > ADAS数据采集及回灌系统开发",
          keys: "ADAS数据采集及回灌系统开发 解决方案 MIL HIL DIL 数据采集 数据管理 数据回灌 CAN CANFD 控制器开发 算法模型训练 LABCAR测试",
        },
        {
          route: "/software",
          label: "产品中心 > Vector软件产品",
          keys: "VECTOR软件产品 CANOE CANAPE CANALYZER CANDELASTUDIO VTESTSTUDIO ODXSTUDIO VFLASH INDIGO VSIGNALYZER VVIRTUALTARGET VMEASURE-EXP ASAP2 TOOL-SET描述文件(A2L) DYNA4 DAVINCI DEVELOPER PREEVISION VECTORCAST",
        },
        {
          route: "/hardware",
          label: "产品中心 > Vector硬件产品",
          keys: "VECTOR硬件产品 SMART LOGGER VH1160 VH5110 VH6501 VN0601 VN1530 VN1531 VN1600 VN2640 VN4610 VN5611 VN5612 VN5601 VN5620 VN5430 VN5620 VN5650 VN5240 VN7572 VN7610 VN7640 VN8810 VN8900 VT SYSTEM VX0312 VX1000",
        },
        {
          route: "/dyna",
          label: "产品中心 > DYNA4",
          keys: "DYNA4 车辆动力学模型 虚拟仿真场景 环境感知算法验证 舒适性功能验证 主动安全系统功能验证 制动控制 转向控制 半主动 主动悬架控制 二氧化碳减排 能量管理预测功能",
        },
        {
          route: "/cast",
          label: "产品中心 > VectorCAST",
          keys: "VECTORCAST 自动化代码测试工具 自动化测试套件 C++",
        },
        {
          route: "/vp67",
          label: "产品中心 > VP6000/VP7000系列",
          keys: "VP6000/VP7000系列 ADAS 高级自动驾驶 CANAPE 总线数据 视频流 雷达原始数据 XCP ON ETHERNET 并行同步记录 写入带宽",
        },
        {
          route: "/vtd",
          label: "产品中心 > Virtual Test Drive(VTD)",
          keys: "Virtual Test Drive(VTD) 仿真环境 模拟 培训 测试 验证 道路网络创建 场景定义 车辆动力学 声音模拟 模拟控制 图像生成 传感器感知 VTD OPENDRIVE OPENCRG OPENSCENARIO",
        },
        {
          route: "/qt",
          label: "产品中心 > Qt",
          keys: "QT 跨平台软件开发框架 设计 开发 测试 部署 UI UX C++",
        },
        {
          route: "/rtaw",
          label: "产品中心 > RTaW-Peagase",
          keys: "RTAW-PEAGASE 车载E/E架构的建模 车载E/E网络架构 自动化配置 精确时间 仿真 模拟 分析 优化 ADAS 高度自动驾驶",
        },
        {
          route: "/nxp",
          label: "产品中心 > NXP解决方案及产品介绍",
          keys: "NXP解决方案及产品介绍 安全互联汽车解决方案 汽车电子产品 ADAS 高度自动驾驶",
        },
      ],
    };
  },
  methods: {
    remoteMethod(searchString) {
      this.selectOptions = [];
      if (searchString && searchString != " ") {
        this.searchOptions.forEach((option) => {
          if (option.keys.includes(searchString.toUpperCase())) {
            this.selectOptions.push(option);
          }
        });
      }
    },
    selectChange(selected) {
      if (selected.includes("#")) {
        // 涉及到hash跳转
        let selectPath = selected.split("#")[0];
        let selectHash = "#" + selected.split("#")[1];
        if (selectPath == this.$route.path) {
          // 跳转位置处于本页面
          this.$emit("checkRouter", selectHash);
        }
      }
      this.$router.push(selected);
      this.searchString = null;
    },
  },
};
</script>
